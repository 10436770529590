import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import CustomLink from "../Elements/CustomLink";

const Article = styled.div`
    margin-bottom: 28px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: ${color("dark")};
    text-align: left;

    &:last-of-type{
        margin-bottom: 0;
        a {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        padding-left: 24px;
        padding-right: 24px;
        width: 50%;
        margin-bottom: 48px;

        :nth-last-of-type(2) {
            :nth-of-type(2n+1) {
                margin-bottom: 0;
                a {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) {
        padding-left: 38px;
        padding-right: 38px;
    }

    @media screen and (min-width: 1800px) {
        padding-left: 58px;
        padding-right: 58px;
    }
`;
const PublishDate = styled.p`
    ${typography.label};
    text-transform: uppercase;
    margin-bottom: 0;
    white-space: nowrap;
`;
const Author = styled.p`
    ${typography.label};
    text-transform: uppercase;
    position: relative;
    font-weight: 600;
`;
const Type = styled.p`
    ${typography.label};
    text-transform: uppercase;
    margin-bottom: 0;
`;
const ArticleTitle = styled.h3`
    ${typography.p1};
    font-weight: bold;
    margin-bottom: 8px;
    line-height: 1.1;

    @media screen and (min-width: 768px) {
        margin-bottom: 12px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 12px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 16px;
    }
    
`;
const ArticleDescription = styled.div`
    ${typography.standard};
    margin-bottom: ${props => props.smallMargin ? "0" : "40px"};
    flex-grow: 1;
`;
const ArticleLink = styled.span`
    ${typography.link};
    display: inline-block;
    text-decoration: underline;
`;
const ArticleDetails = styled(CustomLink)`
    text-decoration: none;
    color: ${color("dark")};
    transition: color 0.2s ease, background 0.2s ease;
    display: inline-block;
    padding: 0 0 28px;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${color("grey")};
    hr{
        border-color: ${color("dark")};
    }

    &:hover{
        color: ${color("dark blue")};

        hr{
            border-top: 1px solid ${color("dark blue")};
        }
    }

    @media screen and (min-width: 768px) {
        padding: 0 0 48px;
    }
`;

const Line = styled.hr`
    text-decoration: none;
    color: inherit;
    width: 34px;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-top-width: 1px;
    margin: 0 0 21px;
    display: none;

    @media screen and (min-width: 1200px) {
        display: inline-block;
        margin: 0 6px;
    }
`;

const Meta = styled.div`
    margin-bottom: 16px;

    @media screen and (min-width: 1200px) {
        margin-bottom: ${props => props.smallMargin ? "16px" : "24px"};
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;

const ArticleComponent = ({careers, search, date, author, type, title, description, url, linkText, internal}) => {

    return (
        <Article>
            <ArticleDetails url={url} internal={internal}>
                <Meta smallMargin={search}>
                    {!search ? (
                        <>
                            <PublishDate>{careers ? `Listed ${date}` : date}</PublishDate>
                            {!careers && author ? (
                                <>
                                    <Line />
                                    <Author>{author}</Author>
                                </>
                            ) : null}
                        </>
                    ) : <Type>{type}</Type>}
                </Meta>
                <ArticleTitle>
                    {title}
                </ArticleTitle>
                <ArticleDescription dangerouslySetInnerHTML={{ __html: description }} smallMargin={search} />
                <ArticleLink>
                    {linkText}
                </ArticleLink>
            </ArticleDetails>
        </Article>
    );
};

export default ArticleComponent;
