import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import HeroGradient from "../components/General/HeroGradient";
import Intro from "../components/General/Intro";
import ArticleBlock from "../components/General/ArticleBlock";
import CTA from "../components/General/CTA";
import Footer from "../components/Context/Footer";
import Philosophy from "../components/General/Philosophy";

import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";

const Careers = ({ data: staticData }) => {
    const data = usePreviewData(staticData);
    const {
        name,
        tagline,
        intro,
        careers,
        heading_promo,
        teaser_promo,
        button_cta,
        link_promo,
        meta_description,
        share_image,
        name_our,
        description_our,
        values,
        name_careers
    } = data.prismicCareers.data;

    const careersList = careers.map((career, index) => {
        return {
            url: career.link.url,
            description: career.teaser.html,
            date: career.date,
            title: career.title.text,
            type: "Career",
            buttonText: "Find out more",
            key: index
        }
    });

    const philosophyContent = values.map((value, index) => {
        return ({
            id: index,
            subheading: value.name_values.text,
            description: value.value.html
        });
    });

    const theme = "light";
    return (
        <Layout theme={theme}>
            <SEO title={name.text} description={meta_description} image={share_image.url} />
            <Header theme={theme} />
            <HeroGradient
                title={name.text}
                subtitle={tagline.html}
                theme={theme}
            />
            <Intro
                content={intro.html}
                theme={theme}
            />
            <Philosophy
                theme={theme}
                title={name_our.text}
                intro={description_our.html}
                content={philosophyContent}
            />
            <ArticleBlock
                title={name_careers.text}
                articles={careersList}
                careers={true}
            />
            <CTA
                theme={theme}
                title={heading_promo.text}
                content={teaser_promo.html}
                buttonText={button_cta}
                buttonUrl={link_promo.url}
                buttonInternal={true}
            />
            <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    {
        prismicCareers {
            data {
                name {
                    text
                }
                tagline {
                    html
                }
                intro {
                    html
                }
                careers {
                    date(formatString: "MMM D YYYY")
                    link {
                        url
                        target
                        link_type
                    }
                    teaser {
                        html
                    }
                    title {
                        text
                    }
                }
                heading_promo {
                    text
                }
                teaser_promo {
                    html
                }
                button_cta
                link_promo {
                    url
                    target
                    link_type
                }
                meta_description
                share_image {
                    url
                }
                name_careers {
                    text
                }
                name_our {
                    text
                }
                description_our {
                    html
                }
                values {
                    name_values {
                        text
                    }
                    value {
                        html
                    }
                }
            }
        }
    }
`;

export default Careers;
