import React from "react";

const ChevronLeftSVG = () => {
    return (
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.46854 5.65533L0.305274 0.841464C0.289169 0.828785 0.269815 0.820906 0.249434 0.818731C0.229052 0.816555 0.208471 0.820173 0.190053 0.829168C0.171635 0.838163 0.156128 0.85217 0.145312 0.869581C0.134496 0.886992 0.12881 0.9071 0.128907 0.927597V1.98443C0.128907 2.05143 0.160353 2.11568 0.212306 2.1567L5.13417 5.99986L0.212306 9.84303C0.158986 9.88404 0.128907 9.9483 0.128907 10.0153V11.0721C0.128907 11.1637 0.234181 11.2143 0.305274 11.1583L6.46854 6.34439C6.52092 6.30353 6.5633 6.25126 6.59245 6.19156C6.62159 6.13186 6.63674 6.0663 6.63674 5.99986C6.63674 5.93343 6.62159 5.86787 6.59245 5.80816C6.5633 5.74846 6.52092 5.69619 6.46854 5.65533Z" transform="scale(-1,1) translate(-7,0)" />
        </svg>
    );
};

export default ChevronLeftSVG;