import React from "react";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import Container from "../Context/Container";
import Section from "../Context/Section";

const IntroWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
`;

const Opening = styled.div`
    ${typography.opening};
    ${typography.RTESpacing};
    width: 100%;

    @media screen and (min-width: 768px) {
        max-width: 100%;
    }

    @media screen and (min-width: 1200px) {
        max-width: 660px;
    }

    @media screen and (min-width: 1800px) {
        max-width: 987px;
    }
`;

const Standard = styled.div`
    ${typography.standard};
    ${typography.RTESpacing};
    width: 100%;
    margin-top: 16px;

    @media screen and (min-width: 768px) {
        margin-top: 0;
        padding-left: 28px;
        width: 110%;
    }

    @media screen and (min-width: 1200px) {
        padding-left: 79px;
        width: 125%;
    }

    @media screen and (min-width: 1800px) {
        padding-left: 59px;
        width: 116%;
    }
`;

const Intro = ({content, extendedContent, homepage, theme}) => {
    return (
        <Section bg={homepage ? "lite blue" : "white"} fg="dark" theme={theme}>
            <Container>
                <IntroWrapper>
                    <Opening dangerouslySetInnerHTML={{ __html: content }} />
                    {extendedContent && <Standard dangerouslySetInnerHTML={{ __html: extendedContent }} />}
                </IntroWrapper>
            </Container>
        </Section>
    );
};

export default Intro;
