import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import Article from "./Article";
import Container from "../Context/Container";
import Section from "../Context/Section";
import Pagination from "../Elements/Pagination";

const ArticleWrapper = styled.div`
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: stretch;
        margin-left: -7.5px;
        margin-right: -7.5px;
    }

    @media screen and (min-width: 768px) {
        margin-left: -24px;
        margin-right: -24px;
    }

    @media screen and (min-width: 1200px) {
        margin-left: -38px;
        margin-right: -38px;
    }

    @media screen and (min-width: 1800px) {
        margin-left: -58px;
        margin-right: -58px;
    }

    ${props => props.hasPagination ? css`
        margin-bottom: 48px;

        @media screen and (min-width: 1200px) {
            margin-bottom: 64px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 72px;
        }

        @media screen and (min-width: 2200px) {
            margin-bottom: 100px;
        }
    ` : null}
`;

const PaginationWrapper = styled.div`
    @media screen and (min-width: 768px) {
        text-align: right;
    }
`;

const Title = styled.h2`
    ${typography.h2};
    margin-bottom: 24px;

    @media screen and (min-width: 768px) {
        margin-bottom: 32px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 42px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 64px;
    }
`;

const ArticleBlock = ({title, articles, careers, search, articlesComponents, pagination, theme}) => {
    const articleList = articles ? articles.map(articleSingle => {
        return (
            <Article
                careers={careers}
                search={search}
                title={articleSingle.title}
                description={articleSingle.description}
                date={articleSingle.date}
                author={articleSingle.author}
                linkText={articleSingle.buttonText}
                url={articleSingle.url}
                internal={articleSingle.type ? false : true}
                key={articleSingle.key}
            />
        )
    }) : null;

    const articleLayout = components => (
        <>
            <ArticleWrapper hasPagination={pagination && pagination.numPages > 1}>
                {components}
            </ArticleWrapper>
            {pagination && pagination.numPages > 1 ? (
                <PaginationWrapper>
                    <Pagination currentPage={pagination.currentPage} numPages={pagination.numPages} baseUrl={pagination.baseUrl} />
                </PaginationWrapper>
            ) : null}
        </>
    );

    return (
        articles ? (
            <Section bg={careers ? "white" : "lite blue"} fg="dark" hr={careers ? "grey" : null} theme={theme}>
                <Container>
                    {title &&
                        <Title>
                            {title}
                        </Title>    
                    }
                    {articleLayout(articleList)}
                </Container>
            </Section>
        ) : (
            articleLayout(articlesComponents)
        )
    );
};

export default ArticleBlock;
