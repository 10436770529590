import React from "react";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import color from "../../styles/colors";
import ChevronRightSVG from "../SVGs/ChevronRightSvg";
import ChevronLeftSVG from "../SVGs/ChevronLeftSvg";
import {Link} from "gatsby";

const PaginationWrapper = styled.div`
    ${typography.label};
    text-align: right;
    ${color("dark grey")};
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a, span{
        display: inline-block;
        padding: 12px;
        width: 10px;
        text-align: center;
    }

    a{
        color: ${color("dark grey")};
        text-decoration: none;
        transition: color 0.2s ease;

        &:hover{
            color: ${color("dark")};
        }
    }

    span{
        font-weight: 600;
    }

    svg{
        fill: ${color("dark")};
    }

    @media screen and (min-width: 768px) {
        display: inline;

        a{
            padding: 8px 12px;
        }

        span{
            padding: 0;
        }

        a, span{
            width: auto;
        }
    }
`;

const CurrentPage = styled.span`
    @media screen and (min-width: 768px) {
        padding: 8px 12px !important;
    }
`;

const Pagination = ({currentPage, numPages, baseUrl}) => {
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? baseUrl : `${baseUrl}${currentPage - 1}`;
    const nextPage = `${baseUrl}/${currentPage + 1}`;
    const start = Math.max(currentPage - 2, 1);
    const end = Math.min(currentPage + 2, numPages);

    return (
        <PaginationWrapper>
            {!isFirst ? <Link to={prevPage} state={{fromPagination: true }} ><ChevronLeftSVG /></Link> : <span></span>}
            {/* {start > 1 ? "…" : null} */}
            {Array.from({length: end + 1 - start}, (_, i) => {
                const page = start + i;
                return page === currentPage ? <CurrentPage key={i}>{page}</CurrentPage> : <Link key={i} to={page === 1 ? `${baseUrl}` : `${baseUrl}${page}`} state={{fromPagination: true }}>{page}</Link>;
            })}
            {/* {end < numPages ? "…" : null} */}
            {!isLast ? <Link to={nextPage} state={{fromPagination: true }} ><ChevronRightSVG /></Link> : <span></span>}
        </PaginationWrapper>
    );
}

export default Pagination;